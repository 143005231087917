import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Stack,
  Button,
  Box,
  Tabs,
  Tab,
  Card,
  CardHeader,
} from "@mui/material";
import moment from "moment";
import { a11yProps } from "../../common/components/TabPanel";
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  getEmployeeExitRequest,
  cancelEmployeeExitRequest,
} from "../../../store/exit/actions";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import ViewExitRequestAttachments from "./ViewExitRequestAttachments";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";

const ViewExitDialog = (props) => {
  const {
    loading,
    employeeExitRequest,
    cancelEmployeeExitRequest,
    showViewExitModal,
    setShowViewExitModal,
    user,
  } = props;
  const [showCancelConfirmationDialog, setShowCancelConfirmationDialog] =
    useState(false);

  const [showLetter, setsShowLetter] = useState(false);
  const onClose = () => {
    setShowCancelConfirmationDialog(false);
    setShowViewExitModal(false);
  };
  // console.log(employeeExitRequest);
  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showViewExitModal}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle>
          <Typography
            variant="overline"
            display="block"
            className="font-weight-bold"
          >
            <span>Exit request</span> /{" "}
            <span className="text-primary mr-1">
              {employeeExitRequest?.exitType || ""}
            </span>
            - {employeeExitRequest?.employeeNames || ""}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>

          <div className="mt-4">
            {employeeExitRequest && (
              <span
                style={{ fontSize: "12px" }}
                className={`${
                  +employeeExitRequest.statusId === 2
                    ? "badge badge-success w-100"
                    : [3].includes(+employeeExitRequest.statusId)
                    ? "badge badge-danger w-100"
                    : [
                        1, 1003, 1005, 1010, 1003, 1005, 1006, 1007, 1008,
                      ].includes(+employeeExitRequest.statusId)
                    ? "badge badge-primary w-100"
                    : [1002].includes(+employeeExitRequest.statusId)
                    ? "badge badge-warning w-100"
                    : "badge badge-secondary w-100"
                }`}
              >
                {employeeExitRequest.statusName}
              </span>
            )}
          </div>
        </DialogTitle>

        <DialogContent>
          {employeeExitRequest && (
            <Box sx={{ width: "100%", minHeight: "95vh" }} className="mt-1">
              {employeeExitRequest &&
                !!employeeExitRequest.enableToGenerateLetter &&
                [3, 2, 1002].includes(employeeExitRequest.statusId) && (
                  <>
                    {[3, 1002].includes(employeeExitRequest.statusId) && (
                      <>
                        <div
                          className="card  w-100"
                          style={{
                            backgroundColor: "#fff",
                            borderLeft: "2px solid #3f51b5",
                            borderLeft: "2px solid #3f51b5",
                            borderRadius: "4px",
                          }}
                        >
                          <div className="card-header w-100">
                            Request {employeeExitRequest.statusName}
                          </div>
                          <div className="card-body w-100">
                            <p className="small p-0 ms-2 mb-0 rounded-3">
                              Reason: {employeeExitRequest.reason}
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}

              <table>
                <tbody>
                  <tr>
                    <th scope="row">Employee</th>
                    <td>
                      {employeeExitRequest.lastName +
                        " " +
                        employeeExitRequest.firstName}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Exit request</th>
                    <td>
                      <strong className="text-primary">
                        {employeeExitRequest.exitType}
                      </strong>
                      {employeeExitRequest.exitTypeId === 0 && (
                        <>
                          <span className="text-capitalize d-block">To</span>
                          <div className="text-capitalize d-block">
                            <div>{employeeExitRequest.toUnitName}</div>
                            <strong className="text-dark">
                              ({employeeExitRequest.toPositionName})
                            </strong>
                          </div>
                        </>
                      )}
                      {employeeExitRequest.exitTypeId === 6 && (
                        <>
                          <span className="text-capitalize d-block">To</span>
                          <div className="t d-block">
                            <div>{employeeExitRequest.toEntityName}</div> on the
                            post of
                            <strong className="ml-1 text-dark">
                              {employeeExitRequest.toPositionName}(
                              {employeeExitRequest.toUnitName})
                            </strong>
                          </div>
                        </>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Reason</th>
                    <td>{employeeExitRequest.reason}</td>
                  </tr>
                  <tr>
                    <th scope="row">Effective on</th>
                    <td>
                      {moment(employeeExitRequest.effectiveDate).format("LL")}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Requested on</th>
                    <td>
                      {moment(employeeExitRequest.createdOn).format("LLL")}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Request by</th>
                    <td>{employeeExitRequest.requestedBy}</td>
                  </tr>
                  {employeeExitRequest.verifiedBy && (
                    <>
                      <tr>
                        <th scope="row">Verified by</th>
                        <td>{employeeExitRequest.verifiedBy}</td>
                      </tr>
                      <tr>
                        <th scope="row">Verified On</th>
                        <td>
                          {" "}
                          {moment(employeeExitRequest.verifiedOn).format("LLL")}
                        </td>
                      </tr>
                    </>
                  )}

                  {employeeExitRequest.statusId === 3 && (
                    <>
                      <tr>
                        <th scope="row">Rejected by</th>
                        <td>{employeeExitRequest.statusByName}</td>
                      </tr>
                      <tr>
                        <th scope="row">Rejected On</th>
                        <td>
                          {" "}
                          {moment(employeeExitRequest.statusOn).format("LLL")}
                        </td>
                      </tr>
                    </>
                  )}
                  {employeeExitRequest.statusId === 2 && (
                    <>
                      <tr>
                        <th scope="row">Approved by</th>
                        <td>{employeeExitRequest.statusByName}</td>
                      </tr>
                      <tr>
                        <th scope="row">Approved On</th>
                        <td>
                          {" "}
                          {moment(employeeExitRequest.statusOn).format("LLL")}
                        </td>
                      </tr>
                    </>
                  )}

                  <tr>
                    <th scope="row">Status</th>
                    <td>
                      <span
                        className={`badge badge-${
                          employeeExitRequest.statusId === 3
                            ? "danger"
                            : employeeExitRequest.statusId === 2
                            ? "success"
                            : employeeExitRequest.statusId === 1002
                            ? "warning"
                            : "primary"
                        }`}
                      >
                        {employeeExitRequest.statusName}
                      </span>
                    </td>
                  </tr>
                  {!!employeeExitRequest.statusComment && (
                    <tr
                      className={`border ${
                        employeeExitRequest.statusId === 2
                          ? "border-success"
                          : "border-danger"
                      }`}
                    >
                      <th scope="row">Comment</th>
                      <td>{employeeExitRequest.statusComment}</td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan={2}>
                      {!!employeeExitRequest.enableToGenerateLetter &&
                        employeeExitRequest.statusId === 2 &&
                        !employeeExitRequest?.docReferenceId && (
                          <>
                            <div
                              className="card  w-100"
                              style={{
                                backgroundColor: "#fff",
                                borderLeft: "2px solid #3f51b5",
                                borderLeft: "2px solid #3f51b5",
                                borderRadius: "4px",
                              }}
                            >
                              <div className="card-body w-100">
                                <p className="small p-0 ms-2 mb-0 rounded-3">
                                  No letter was found because the request was
                                  approved when this feature wasn't in the
                                  system.
                                </p>
                              </div>
                            </div>
                          </>
                        )}

                      {employeeExitRequest.statusId === 2 &&
                        !!employeeExitRequest?.docReferenceId && (
                          <div className="d-flex justify-content-center w-100">
                            <Card
                              className={`mb-2 w-100 rounded card border-primary hoverable-card`}
                              elevation={1}
                              variant="elevation"
                              style={{
                                backgroundColor: "#fff",
                              }}
                            >
                              <CardHeader
                                avatar={
                                  <span
                                    className={"font-weight-bold text-success"}
                                  >
                                    <TextSnippetIcon />
                                  </span>
                                }
                                title={
                                  <Typography>
                                    <strong className={"text-success"}>
                                      View {employeeExitRequest.exitType} signed
                                      letter.
                                    </strong>
                                  </Typography>
                                }
                                action={
                                  <>
                                    <div
                                      className="d-flex flex-row align-items-center pull-right"
                                      style={{ borderRadius: "4px" }}
                                    >
                                      <IconButton
                                        size="small"
                                        onClick={() => {
                                          setsShowLetter(true);
                                        }}
                                      >
                                        <FilePresentIcon
                                          style={{
                                            color: "#fe4066",
                                          }}
                                        />
                                      </IconButton>
                                    </div>
                                  </>
                                }
                              />
                            </Card>
                          </div>
                        )}
                    </td>
                  </tr>
                </tbody>
              </table>

              <ViewExitRequestAttachments
                requestId={employeeExitRequest.id}
                canEdit={
                  [1010].includes(employeeExitRequest.statusId) &&
                  employeeExitRequest.requestedById === user.id
                }
              />
            </Box>
          )}

          {showLetter &&
            employeeExitRequest.statusId === 2 &&
            !!employeeExitRequest?.docReferenceId && (
              <PreviewPdfUrl
                showDocument={showLetter}
                setShowDocument={setsShowLetter}
                document={{
                  name: `View ${employeeExitRequest.exitType} Letter`,
                  url:
                    process.env.REACT_APP_FILES_URL +
                    "/preview/" +
                    employeeExitRequest?.docReferenceId,
                }}
              />
            )}
        </DialogContent>
        {employeeExitRequest && (
          <DialogActions>
            <Stack direction="row" spacing={2} className="mx-auto">
              {[1010].includes(employeeExitRequest.statusId) &&
                employeeExitRequest.requestedById === user.id && (
                  <Button
                    variant="contained"
                    color="warning"
                    startIcon={<span className="material-icons">cancel</span>}
                    onClick={() => setShowCancelConfirmationDialog(true)}
                    disabled={loading}
                  >
                    {loading ? "Canceling..." : "Cancel request"}
                  </Button>
                )}
            </Stack>
          </DialogActions>
        )}
      </Dialog>
      {showCancelConfirmationDialog && employeeExitRequest && (
        <ConfirmationDialog
          confirmationDialog={showCancelConfirmationDialog}
          message={`Are you sure you want to cancel the exit request for ${
            employeeExitRequest?.lastName + " " + employeeExitRequest?.firstName
          }?`}
          setConfirmationDialog={setShowCancelConfirmationDialog}
          onYes={() => {
            cancelEmployeeExitRequest(employeeExitRequest, onClose);
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  loading,
  employeeExitRequest,
  env,
  exitRequestAttachments,
  user,
}) => {
  return {
    loading,
    employeeExitRequest,
    env,
    exitRequestAttachments,
    user,
  };
};
export default connect(mapStateToProps, {
  getEmployeeExitRequest,
  cancelEmployeeExitRequest,
})(ViewExitDialog);
