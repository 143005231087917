import {
  Alert,
  AlertTitle,
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import EditIcon from "@mui/icons-material/Edit";
import { connect } from "react-redux";
import PdfViewer from "../../common/components/PdfViewer";
import {
  updateExitRequestAttachment,
  addExitRequestAttachment,
  getExitRequestAttachments,
} from "../../../store/exit/actions";
import { showError } from "../../toastify";
import TableSkeleton from "../../common/components/TableSkeleton";

const ViewExitRequestAttachments = (props) => {
  const {
    exitRequestAttachments,
    loading,
    canEdit,
    updateExitRequestAttachment,
    addExitRequestAttachment,
    onWait,
    requestId,
    getExitRequestAttachments,
  } = props;

  const [showDocument, setShowDocument] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [editFile, setEditFile] = useState(false);
  const [addFile, setAddFile] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getExitRequestAttachments(requestId, setIsLoading);
  }, [requestId]);
  return (
    <>
      <div>
        <Box sx={{ display: "flex", width: "100%" }}>
          <div className="w-100">
            {showDocument && selectedDocument && (
              <PreviewPdfUrl
                showDocument={showDocument}
                setShowDocument={setShowDocument}
                document={{
                  name: `View ${selectedDocument.filename}`,
                  url:
                    process.env.REACT_APP_FILES_URL +
                    "/preview/" +
                    selectedDocument.docReferenceId,
                }}
              />
            )}
            {editFile && (
              <UpdateAttachmentFile
                editFile={editFile}
                setEditFile={setEditFile}
                setSelectedDocument={setSelectedDocument}
                selectedDocument={selectedDocument}
                updateExitRequestAttachment={updateExitRequestAttachment}
                onWait={onWait}
                loading={loading}
              />
            )}
            {addFile && (
              <AddAttachmentFile
                addFile={addFile}
                setAddFile={setAddFile}
                setSelectedDocument={setSelectedDocument}
                selectedDocument={selectedDocument}
                addExitRequestAttachment={addExitRequestAttachment}
                onWait={onWait}
                loading={loading}
                requestId={requestId}
              />
            )}
            <AppBar
              position="static"
              elevation={0}
              className="w-100 text-white"
            >
              <Toolbar>
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{ display: { xs: "none", sm: "block" } }}
                >
                  <span>Supporting document(s)</span>
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ display: { xs: "flex", md: "flex" } }}>
                  {canEdit && (
                    <Button
                      variant="contained"
                      color="warning"
                      size="small"
                      className="mb-2 float-right"
                      startIcon={
                        <span className="material-icons mb-1">add</span>
                      }
                      onClick={() => {
                        setAddFile(true);
                      }}
                    >
                      Add document
                    </Button>
                  )}
                </Box>
              </Toolbar>
            </AppBar>
            <div
              style={{
                height: "auto",
                maxHeight: "auto",
                backgroundColor: "#fff",
                borderRadius: "0.5rem",
                overflow: "visible",
                boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
              }}
            >
              <table className="table-striped table-hover table-sm  fixTableHead mt-0">
                <thead>
                  <tr style={{ backgroundColor: "#f0f2f5" }}>
                    <th scope="col" className="text-left">
                      #
                    </th>
                    <th scope="col" className="text-left">
                      name
                    </th>

                    <th scope="col" className="text-right">
                      <span className="mr-3"></span>
                    </th>
                  </tr>
                </thead>
                {isLoading ? (
                  <TableSkeleton cols={3} />
                ) : (
                  <tbody>
                    {exitRequestAttachments.map((attachment, index) => (
                      <tr
                        key={index}
                        className="appeal-hovered"
                        onClick={() => {
                          if (canEdit) {
                            setSelectedDocument(attachment);
                            setEditFile(true);
                          } else {
                            setSelectedDocument(attachment);
                            setShowDocument(true);
                          }
                        }}
                      >
                        <td className="text-left">
                          <strong> {index + 1} </strong>
                        </td>

                        <td className="text-left">
                          <strong>
                            <AttachFileIcon className="text-primary" />
                            View {attachment.filename}{" "}
                          </strong>
                        </td>

                        <td className="text-right">
                          <button
                            type="button"
                            className={`btn ${
                              canEdit ? "btn-primary" : "btn-success"
                            } m-2`}
                          >
                            {canEdit ? (
                              <>
                                <EditIcon /> Edit
                              </>
                            ) : (
                              <>
                                {" "}
                                <OpenInNewIcon /> Open
                              </>
                            )}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
              {!exitRequestAttachments.length && !isLoading && (
                <>
                  <div className="text-center">
                    <p className="lead">No Attachment found</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </Box>
      </div>
    </>
  );
};
const mapStateToProps = ({ loading, onWait, exitRequestAttachments }) => {
  return {
    loading,
    onWait,
    exitRequestAttachments,
  };
};
export default connect(mapStateToProps, {
  updateExitRequestAttachment,
  getExitRequestAttachments,
  addExitRequestAttachment,
})(ViewExitRequestAttachments);

const AddAttachmentFile = (props) => {
  const {
    loading,
    addFile,
    setAddFile,
    addExitRequestAttachment,
    requestId,
    onWait,
  } = props;
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({
    fileHasError: false,
    fileError: "",
    hasError: false,
  });
  const [documentUrl, setDocumentUrl] = useState(null);
  const formValidator = () => {
    const error = {
      fileHasError: false,
      fileError: "",
      hasError: false,
    };

    if (!file) {
      error.fileHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all the required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      const tempFormData = new FormData();
      // tempFormData.append("id", selectedDocument.id);
      tempFormData.append("requestId", requestId);
      tempFormData.append("file", file);

      addExitRequestAttachment(tempFormData, onClose);
    }
  };

  const onClose = () => {
    setFile(null);
    setAddFile(false);
  };

  return (
    <Dialog open={addFile} fullWidth maxWidth="md">
      <DialogTitle className="text-primary pb-0 text-truncate">
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      <DialogContent className="pb-0">
        <div
          style={{
            height: "600px",
          }}
        >
          <h5
            style={{
              background: "rgba(0,99,207,.08)",
              padding: "10px 25px",
              borderRadius: "5px",
              margin: "0 25px 32px 0",
              width: "-moz-fit-content",
              width: "fit-content",
              color: "#0063cf",
            }}
          >
            Add File
          </h5>

          <div className="row ">
            <div className={`col-12 py-2 px-2 mt-0`}>
              <small>Upload new file (allowed:pdf)</small>
              <Button
                fullWidth
                className="d-flex justify-content-start"
                component="label"
                style={{
                  //float: "right",
                  borderRadius: "0px",
                  height: "35px",
                  border: `1px solid #6c757d`,
                  color: "#fff",
                  alignItems: "center",
                  fontSize: ".75rem",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textTransform: "initial",
                  backgroundColor: "#6c757d",
                  "&:hover": {
                    backgroundColor: "#6c757d",
                  },
                }}
                size="md"
              >
                <input
                  accept="application/pdf"
                  type="file"
                  onChange={(e) => {
                    setErrors({
                      ...errors,
                      fileHasError: false,
                      fileError: "",
                    });
                    const file = e.target.files[0];
                    setFile(file || null);
                    const reader = new FileReader();

                    reader.onload = (e) => {
                      const documentUrl = e.target.result;

                      setDocumentUrl(documentUrl);
                    };

                    reader.readAsDataURL(file);
                  }}
                />
              </Button>
              {errors.fileHasError && (
                <small className="text-danger">
                  {errors.fileError || "Attachment is required"}
                </small>
              )}
            </div>
            <div className="col-12 mt-3">
              {!!documentUrl && <PdfViewer isBase64={true} url={documentUrl} />}
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="py-3 d-flex justify-content-center">
        <button
          onClick={onSave}
          type="button"
          className="btn btn-primary text-uppercase"
          disabled={loading || onWait}
        >
          {onWait ? "Wait..." : "Save"}
        </button>
      </DialogActions>
    </Dialog>
  );
};
const UpdateAttachmentFile = (props) => {
  const {
    loading,
    editFile,
    setEditFile,
    updateExitRequestAttachment,
    selectedDocument,
    setSelectedDocument,
    onWait,
  } = props;
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({
    fileHasError: false,
    fileError: "",
    hasError: false,
  });
  const [documentUrl, setDocumentUrl] = useState(null);
  const formValidator = () => {
    const error = {
      fileHasError: false,
      fileError: "",
      hasError: false,
    };

    if (!file) {
      error.fileHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all the required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      const tempFormData = new FormData();
      tempFormData.append("requestId", selectedDocument.exitRequestId);
      tempFormData.append("file", file);

      updateExitRequestAttachment(selectedDocument.id, tempFormData, onClose);
    }
  };

  const onClose = () => {
    setSelectedDocument(null);
    setFile(null);
    setEditFile(false);
  };

  return (
    <Dialog open={editFile} fullWidth maxWidth="md">
      <DialogTitle className="text-primary pb-0 text-truncate">
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      <DialogContent className="pb-0">
        <div
          style={{
            height: "600px",
          }}
        >
          {selectedDocument && (
            <h5
              style={{
                background: "rgba(0,99,207,.08)",
                padding: "10px 25px",
                borderRadius: "5px",
                margin: "0 25px 32px 0",
                width: "-moz-fit-content",
                width: "fit-content",
                color: "#0063cf",
              }}
            >
              {selectedDocument.filename}
            </h5>
          )}

          <div className="row ">
            <div className={`col-12 py-2 px-2 mt-0`}>
              <small>Upload new file (allowed:pdf)</small>
              <Button
                fullWidth
                className="d-flex justify-content-start"
                component="label"
                style={{
                  //float: "right",
                  borderRadius: "0px",
                  height: "35px",
                  border: `1px solid #6c757d`,
                  color: "#fff",
                  alignItems: "center",
                  fontSize: ".75rem",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textTransform: "initial",
                  backgroundColor: "#6c757d",
                  "&:hover": {
                    backgroundColor: "#6c757d",
                  },
                }}
                size="md"
              >
                <input
                  accept="application/pdf"
                  type="file"
                  onChange={(e) => {
                    setErrors({
                      ...errors,
                      fileHasError: false,
                      fileError: "",
                    });
                    const file = e.target.files[0];
                    setFile(file || null);
                    const reader = new FileReader();

                    reader.onload = (e) => {
                      const documentUrl = e.target.result;

                      setDocumentUrl(documentUrl);
                    };

                    reader.readAsDataURL(file);
                  }}
                />
              </Button>
              {errors.fileHasError && (
                <small className="text-danger">
                  {errors.fileError || "Attachment is required"}
                </small>
              )}
            </div>
            <div className="col-12 mt-3">
              {selectedDocument && (
                <PdfViewer
                  isBase64={!!documentUrl ? true : false}
                  url={
                    documentUrl
                      ? documentUrl
                      : process.env.REACT_APP_FILES_URL +
                        "/preview/" +
                        selectedDocument.docReferenceId
                  }
                />
              )}
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="py-3 d-flex justify-content-center">
        <button
          onClick={onSave}
          type="button"
          className="btn btn-primary text-uppercase"
          disabled={loading || onWait}
        >
          {onWait ? "Wait..." : "Update"}
        </button>
      </DialogActions>
    </Dialog>
  );
};
